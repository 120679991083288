import {
  CommonModule,
  NgOptimizedImage,
  provideImageKitLoader,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbModalModule,
  NgbToastModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { AppstoreButtonsComponent } from '../shared/appstore-buttons/appstore-buttons.component';
import { BypassSecurityPipe } from '../shared/bypass-security.pipe';
import { LoadingPageComponent } from '../shared/loading-page/loading-page.component';
import { UserButtonComponent } from '../shared/user-button/user-button.component';
import { Utility } from '../shared/utility';
import { CalendarSvg } from '../svg/calendar/calendar.svg';
import { CheckmarkSvg } from '../svg/checkmark/checkmark.svg';
import { EducationSvg } from '../svg/education/education.svg';
import { ExitCircleSvg } from '../svg/exit-circle/exit-circle.svg';
import { ExitSvg } from '../svg/exit/exit.svg';
import { ExternalLinkSvg } from '../svg/external-link/external-link.svg';
import { FacebookWhiteCircleSvg } from '../svg/facebook-white-circle/facebook-white-circle.svg';
import { HeartFilledSvg } from '../svg/heart-filled/heart-filled.svg';
import { InstagramWhiteCircleSvg } from '../svg/instagram-white-circle/instagram-white-circle.svg';
import { MenuSvg } from '../svg/menu/menu.svg';
import { NotebookSvg } from '../svg/notebook/notebook.svg';
import { PinterestWhiteCircleSvg } from '../svg/pinterest-white-circle/pinterest-white-circle.svg';
import { PlaySvg } from '../svg/play/play.svg';
import { SearchSvg } from '../svg/search/search.svg';
import { VideoSvg } from '../svg/video/video.svg';
import { WarningSvg } from '../svg/warning/warning.svg';
import { YeLogoDarkSvg } from '../svg/ye-logo-dark/ye-logo-dark.svg';
import { YeLogoLightSvg } from '../svg/ye-logo-light/ye-logo-light.svg';
import { YogaSvg } from '../svg/yoga/yoga.svg';
import { YoutubeWhiteCircleSvg } from '../svg/youtube-white-circle/youtube-white-circle.svg';
import { AnnouncementComponent } from './announcement/announcement.component';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { BottomsheetComponent } from './bottomsheet/bottomsheet.component';
import { CoreState } from './core.state';
import { FooterComponent } from './footer/footer.component';
import { MobileNavigationComponent } from './mobile-navigation/mobile-navigation.component';
import { JoinProgramSuccessModalContentComponent } from './modal/join-program-success-modal-content/join-program-success-modal-content.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SearchButtonComponent } from './navbar/search-button/search-button.component';
import { RedirectToComponent } from './redirect-to/redirect-to.component';
import { RequestCountInterceptor } from './request-count/request-count.interceptor';
import { SocialCirclesComponent } from './social-circles/social-circles.component';
import { StickyCtaButtonComponent } from './sticky-cta-button/sticky-cta-button.component';
import { ToastComponent } from './toast/toast.component';

const getProviders = () => {
  if (Utility.isPrerender()) {
    return [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestCountInterceptor,
        multi: true,
      },
      provideImageKitLoader('https://ik.imagekit.io/ye'),
    ];
  }
  return [provideImageKitLoader('https://ik.imagekit.io/ye')];
};

@NgModule({
  declarations: [
    AnnouncementComponent,
    AutofocusDirective,
    BottomsheetComponent,
    FooterComponent,
    JoinProgramSuccessModalContentComponent,
    MobileNavigationComponent,
    NavbarComponent,
    SearchButtonComponent,
    SocialCirclesComponent,
    StickyCtaButtonComponent,
    ToastComponent,
    RedirectToComponent,
  ],
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule, // why is this needed here??
    NgOptimizedImage,
    NgxsModule.forFeature([CoreState]),
    CommonModule,
    NgbModalModule,
    NgbToastModule,
    NgbTooltipModule,
    UserButtonComponent,
    AppstoreButtonsComponent,
    BypassSecurityPipe,
    ExitSvg,
    WarningSvg,
    HeartFilledSvg,
    CheckmarkSvg,
    SearchSvg,
    ExternalLinkSvg,
    YeLogoLightSvg,
    MenuSvg,
    EducationSvg,
    NotebookSvg,
    YogaSvg,
    VideoSvg,
    CalendarSvg,
    PlaySvg,
    YeLogoDarkSvg,
    ExitCircleSvg,
    YoutubeWhiteCircleSvg,
    PinterestWhiteCircleSvg,
    InstagramWhiteCircleSvg,
    FacebookWhiteCircleSvg,
    LoadingPageComponent
  ],
  exports: [
    AnnouncementComponent,
    BottomsheetComponent,
    FooterComponent,
    NavbarComponent,
    MobileNavigationComponent,
    ToastComponent,
    StickyCtaButtonComponent,
  ],
  providers: getProviders(),
})
export class CoreModule {}
